.assessment-history {
    height: 95vh;
    overflow-y: scroll;
    width: 70%;
    padding: 1% 5%;
}
.header-container {
    justify-content: space-between;
    padding: 0px 15px;

    .header {
        font-size: 0.9rem;
        margin: 15px 0px 0px 0px;
    }
}