.first-row {
    justify-content: space-between;
    font-size: 0.9rem;
}
.second-row {
    margin-top: 3%;
    justify-content: flex-start;
    font-size: 0.8rem;

    .column {
        margin-left: 5px;
    }
}
.item-container {
    margin-top: 2%;
    box-shadow: 0px 2px 5px 1px hsla(215, 20%, 70%, 0.3);
}
.item-container:hover {
    margin-top: 2%;
    box-shadow: 0px 2px 5px 1px hsla(215, 20%, 70%, 1);
    transition: all 0.3s ease-out;
}

.status {
    font-size: 0.8rem;
    padding: 3px;
    border: 1px solid;
}
.submitted {
    color: green;
    border-color: green;
}
.expired {
    color: red;
    border-color: red;
}
.details-row {
    justify-content: space-between;
    font-size: 0.8rem;

    .column.middle {
        margin-left: -15%;
    }
}