body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spinner {
  width: 100vw;
  height: 100vh;
}

.flex-container {
  display: flex !important;
}
.direction-column {
  flex-direction: column;
}
.align-center {
  justify-content: center;
  align-items: center;
}
.align-end {
  justify-content: flex-end;
  align-items: flex-end;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.top-margin {
  margin-top: 2rem;
}
.assessment-history th,.assessment-history td {
  border-left: 1px solid rgba(224, 224, 224, 1);
  text-align: center !important;
}
.assessment-history table {
  border: 1px solid gray;
}
.semi-bold-font {
  font-weight: 600;
}
.bold-font {
  font-weight: 700;
}
.light-font {
  font-weight: 300;
}
