.initiate-assessment {
    padding:1% 5%;
    height: 95vh;
    overflow-y: scroll;
    width: 70%;
}
.select-assessment {
    margin-left: 2% !important;
}
.file-upload {
    visibility: hidden;
    width: 0px;
    height: 0px;
}