.select-email {
    margin-left: 2% !important;
}
.chips {
    padding: 20px 5px !important;
    margin: 1%;
    .MuiChip-label {
        width: 100%;
    }
}
.chips-container {
    height: 40vh;
    width: 100%;
    overflow: auto;
    padding: 1%;
    margin-top: 5%;
    box-shadow: 0px 0px 3px 0px hsla(0, 0%, 50%, 0.4);
    justify-content: flex-start;
}