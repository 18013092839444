.side-tabs {
    width: 20%;
    height: 100vh;
    overflow-y: scroll;
    box-shadow: 1px 1px 10px hsla(0, 0%, 50%, 0.5);
}
.side-tabs {
    .MuiTabs-flexContainer {
        height: 100%;
    }
}
.logout {
    position: absolute !important;
    width: 100%;
    bottom: 0px;
}